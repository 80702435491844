import { defineMessages } from 'react-intl';

export const frontpage = 'frontpage';
export const frontpageMessages = defineMessages({
  pageTitle: {
    id: 'frontpage:pageTitle',
    defaultMessage: 'Innskráning',
    description: 'Titill síðu',
  },

  title: {
    id: `frontpage:title`,
    defaultMessage: 'Innskráning á sjóðfélagavef',
    description: 'Titill forsíðu',
  },
  disclaimer: {
    id: `frontpage:disclaimer`,
    defaultMessage:
      'Með því að skrá þig inn á vefsíðuna telst þú hafa samþykkt notendaskilmála Þessa Sjóðs.',
    description: 'Fyrirvari á innskráningarsíðu',
  },
  telAuthTitle: {
    id: `frontpage:tel-auth-title`,
    defaultMessage: 'Skilríki á síma',
    description: 'Texti á síma auðkenningarflipa',
  },
  telAuthPlaceholder: {
    id: `frontpage:tel-auth-placeholder`,
    defaultMessage: 'Sláðu inn símanúmer',
    description: 'Texti í símanúmera reit',
  },
  telAuthButton: {
    id: `frontpage:tel-auth-button`,
    defaultMessage: 'Auðkenna',
    description: 'Texti á síma auðkenningarhnappi',
  },
  appAuthTitle: {
    id: `frontpage:app-auth-title`,
    defaultMessage: 'Auðkenning í appi ',
    description: 'Texti á app auðkenningarflipa',
  },
  appAuthPlaceholder: {
    id: `frontpage:app-auth-placeholder`,
    defaultMessage: 'Sláðu inn kennitölu',
    description: 'Texti í kennitölu reit',
  },
  appAuthButton: {
    id: `frontpage:app-auth-button`,
    defaultMessage: 'Auðkenna',
    description: 'Texti á app auðkenningarhnappi',
  },
  appAuthVerificationCode: {
    id: `frontpage:app-auth-verification-code`,
    defaultMessage: 'Öryggiskóði:',
    description: 'Texti í staðfestingarkóða reit',
  },
  cardAuthTitle: {
    id: `frontpage:card-auth-title`,
    defaultMessage: 'Skilríki á korti',
    description: 'Texti á korta auðkenningarflipa',
  },
  cardAuthButton: {
    id: `frontpage:card-auth-button`,
    defaultMessage: 'Ísland.is',
    description: 'Texti á korta auðkenningarhnappi',
  },
  islykillAuthTitle: {
    id: `frontpage:islykill-auth-title`,
    defaultMessage: 'Íslykill',
    description: 'Texti á island.is auðkenningarflipa',
  },
  islykillAuthButton: {
    id: `frontpage:islykill-auth-button`,
    defaultMessage: 'Ísland.is',
    description: 'Texti á island.is auðkenningarhnappi',
  },
  failedAuthTitle: {
    id: `frontpage:failed-auth-title`,
    defaultMessage: 'Auðkenning tókst ekki',
    description: 'Titill á modal ef innskráning tekst ekki',
  },
  failedAuthText: {
    id: `frontpage:failed-auth-text`,
    defaultMessage:
      'Ekki tókst að auðkenna þig með rafrænum skilríkjum. Vinsamlega hafið samband í gegnum tölvupóst eða í síma og við athugum málið.',
    description: 'Texti á modal ef innskráning tekst ekki',
  },
});
