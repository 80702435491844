import { ReactNode } from 'react';
import Tippy from '@tippyjs/react';
import InfoIcon from 'assets/svg/ui/information.svg';

import 'tippy.js/animations/shift-away-subtle.css';
import s from './Tooltip.module.scss';

type Props = {
  tooltip: ReactNode;
};

export const Tooltip = (props: Props) => {
  const { tooltip } = props;
  return (
    <Tippy
      content={tooltip}
      theme="tippy-override"
      animation="shift-away-subtle"
      touch={true}
      placement="top"
    >
      <div>
        <InfoIcon className={s.tooltip__icon} />
      </div>
    </Tippy>
  );
};
