import { Children } from 'react';
import classnames from 'classnames/bind';

import { H3 } from 'components/heading/Heading';

import s from './FormLayout.module.scss';

const c = classnames.bind(s);

type IProps = {
  children?: React.ReactNode;
  wide?: boolean;
};

export const FormLayoutItem = ({ children, wide }: IProps) => (
  <div
    className={c(s.formLayout__item, {
      wide,
    })}
  >
    {children}
  </div>
);

export const FormLayout = ({
  title,
  children,
}: {
  title?: string;
  children: React.ReactNode;
}) => {
  const childArray = Children.toArray(children).filter(Boolean);

  if (!childArray.length) {
    return null;
  }

  return (
    <>
      {title && (
        <H3 as="h2" className={s.formLayout__title}>
          {title}
        </H3>
      )}
      <div className={s.formLayout}>
        {childArray.map((child, i) => (
          <FormLayoutItem key={i}>{child}</FormLayoutItem>
        ))}
      </div>
    </>
  );
};
