import { Link } from 'components/link/Link';
import { Logo } from 'components/logo/Logo';

import s from './HeaderLogin.module.scss';

export const HeaderLogin = () => {
  return (
    <header className={s.headerLogin}>
      <div className={s.headerLogin__container}>
        <a tabIndex={0} className={s.headerLogin__skip} href="#main">
          Skip to content
        </a>

        <Link tabIndex={0} to="/" title="Forsíða" className={s.headerLogin__logo}>
          <Logo />
        </Link>
      </div>
    </header>
  );
};
