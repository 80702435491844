import classnames from 'classnames/bind';

import s from './Message.module.scss';

const c = classnames.bind(s);

type IProps = {
  children: React.ReactNode;
  id: string;
  className?: string;
};

export const Message = ({ children, id, className }: IProps) => {
  return (
    <div className={c(s.container)}>
      <div id={id} className={c(s.message, className)}>
        {children}
      </div>
    </div>
  );
};
