import React, { cloneElement } from 'react';
import classnames from 'classnames/bind';

import { HeaderLogin } from 'components/header/HeaderLogin';
import { LocaleSelect } from 'components/locale-select/LocaleSelect';

import s from './Login.module.scss';

const c = classnames.bind(s);

type IProps = {
  children: React.ReactNode;
  image?: React.ReactNode;
  locales?: Array<string>;
  selectedLocale?: string;
};

export const Login = ({ children, image, locales, selectedLocale }: IProps) => {
  return (
    <div className={c(s.login)}>
      <div className={s.login__contentWrap}>
        <div className={s.login__contentTop}>
          <HeaderLogin />
          {locales && locales.length > 1 && (
            <LocaleSelect locales={locales} selectedLocale={selectedLocale} />
          )}
        </div>
        <div className={s.login__content}>
          <div className={s.login__contentInner}>{children}</div>
        </div>
      </div>
      <div className={s.login__imageWrap}>
        {cloneElement(image as React.ReactElement, {
          className: s.login__image,
        })}
      </div>
    </div>
  );
};
