import { ChangeEvent, ComponentPropsWithoutRef, useCallback } from 'react';

import { TextInput } from 'components/form-elements/TextInput';

const replaceWithOnlyNumbers = (value: string): string => {
  return value.replace(/[^0-9]/g, '');
};

const formatter = (value: string) => {
  const onlyNumbers = replaceWithOnlyNumbers(value);
  const firstPart = onlyNumbers.slice(0, 6);
  const secondPart = onlyNumbers.slice(6, 10);

  return [firstPart, secondPart].filter(Boolean).join('-');
};

type OwnProps = {
  value: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

type PropsToExclude = 'maxLength' | 'type' | keyof OwnProps;

type Props = OwnProps & Omit<ComponentPropsWithoutRef<typeof TextInput>, PropsToExclude>;

export const SsnInput = (props: Props) => {
  const { onChange } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = replaceWithOnlyNumbers(e.target.value);

      if (value.length > 10 || !onChange) {
        return;
      }

      onChange({ ...e, target: { ...e.target, value } });
    },
    [onChange]
  );

  return (
    <TextInput
      {...props}
      value={formatter(props.value)}
      onChange={handleChange}
      maxLength={11}
      type="tel"
    />
  );
};

SsnInput.formatter = formatter;
