import { ChangeEvent, ComponentPropsWithoutRef, useCallback } from 'react';

import { TextInput } from 'components/form-elements/TextInput';

const replaceWithOnlyNumbers = (value: string): string => value.replace(/[^0-9]/g, '');

type OwnProps = {
  value: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  login?: boolean;
};

type PropsToExclude = 'maxLength' | 'type' | keyof OwnProps;

type Props = OwnProps & Omit<ComponentPropsWithoutRef<typeof TextInput>, PropsToExclude>;

export const TelephoneInput = (props: Props) => {
  const { onChange } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return;
      }
      const value = replaceWithOnlyNumbers(e.target.value);
      onChange({ ...e, target: { ...e.target, value } });
    },
    [onChange]
  );

  return <TextInput {...props} onChange={handleChange} maxLength={7} type="tel" />;
};
